<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import TooltipTable from '@/components/tooltip-table.vue';
import draggable from 'vuedraggable'

import { required, integer, minValue, requiredIf } from "vuelidate/lib/validators";
import * as XLSX from "xlsx";
import Multiselect from 'vue-multiselect';

import {
  FETCH_FILE_MANAGEMENT_DATA,
  POST_FILE_MANAGEMENT_DATA,
  FETCH_ALL_PAYMENT_CHANNEL
} from "@/state/actions.type";
import cloneDeep from 'lodash.clonedeep';
import Common from '../../../helpers/Common';
export default {
  components: {
    // Layout,
    // PageHeader,
    TooltipTable,
    draggable,
    Multiselect
  },
  validations: {
    form: {
      step2: {
        offset: {
          required,
          integer,
          minValue: minValue(0)
        },
        refund_offset: {
          requiredIf: requiredIf(function () {
            return this.refundIncluded;
          }),
          integer,
          minValue: minValue(0)
        },
        sheet_number: {
          required,
          integer,
          minValue: minValue(0)
        },
        refund_sheet_number: {
          requiredIf: requiredIf(function () {
            return this.refundIncluded;
          }),
          minValue: minValue(0)
        },
        file: {
          requiredIf: requiredIf(function () {
            return !this.form.step1.source_name;
          })
        },
      },
      step1: {
        display_name: {
          required
        },
        source_date_format: {
          required
        },
        currency: {
          required
        },
        payment_channel_key: {
          required
        }
      }
    },
  },
  data() {
    return {
      fields: [
      { key: "display_name", label: "Display Name" },
        { key: "charge_params", label: "Charge Params" },
        { key: "header_mapping", label: "Header Mapping" },
        { key: "refund_header_mapping", label: "Refund Header Mapping" },
        { key: "offset", label: "Offset" },
        { key: "refund_offset", label: "Refund Offset" },
        { key: "sheet_number", label: "Sheet No" },
        { key: "refund_sheet_number", label: "Refund Sheet No" },
        { key: "source_date_format", label: "Source Data Format" },
        { key: "source_name", label: "File Name" },
        { key: "action", label: "Action", stickyColumn: true },
      ],
      modalTableData: null,
      modalTitle: "",
      paymentChannelList: [],
      formPaymentChannelList: [],
      selectedPSP: null,
      form: {
        step2: {
          offset: 0,
          refund_offset: 0,
          sheet_number: 0,
          refund_sheet_number: 0,
          file: null,
        },
        step1: {
          display_name: "",
          source_date_format: "",
          currency: '',
          payment_channel_key: null,
          file_type: "recon"
        },
        charge_params: {
          fee: "",
          tax: ""
        },
        all_headers: []
      },
      currentItem: null,
      fileHeaders: [],
      refundFileHeaders: [],
      refundIncluded: false,
      formStep: 1,
      isSubmited: false,
      isEdit: false,
      dateOptions: [
        {text: "DD/MM/YYYY", value: "02/01/2006"},
        {text: "MM/DD/YYYY", value: "01/02/2006"},
        {text: "YYYY-MM-DD", value: "2006-01-02"},
        {text: "Month D, YYYY", value: "January 2, 2006"},
        {text: "D MMM, YYYY", value: "2 Jan, 2006"},
        {text: "D MMMM, YYYY", value: "2 January, 2006"},
        {text: "DD/MM/YY", value: "02/01/06"},
        {text: "MM/DD/YY", value: "01/02/06"},
        {text: "YY-MM-DD", value: "06-01-02"},
        {text: "D/M/YYYY", value: "2/1/2006"},
        {text: "D.M.YYYY", value: "2.1.2006"},
        {text: "D-MMM-YY", value: "2-Jan-06"},
        {text: "DD-MM-YYYY HH:mm:ss", value: "02-01-2006 15:04:05"},
        {text: "MM-DD-YYYY HH:mm:ss", value: "01-02-2006 15:04:05"},
        {text: "YYYY-MM-DD HH:mm:ss", value: "2006-01-02 15:04:05"},
        {text: "Month D, YYYY HH:mm:ss", value: "January 2, 2006 15:04:05"},
        {text: "D MMM, YYYY HH:mm:ss", value: "2 Jan, 2006 15:04:05"},
        {text: "D MMMM, YYYY HH:mm:ss", value: "2 January, 2006 15:04:05"},
        {text: "DD/MM/YY HH:mm:ss", value: "02/01/06 15:04:05"},
        {text: "MM/DD/YY HH:mm:ss", value: "01/02/06 15:04:05"},
        {text: "YY-MM-DD HH:mm:ss", value: "06-01-02 15:04:05"},
        {text: "D/M/YYYY HH:mm:ss", value: "2/1/2006 15:04:05"},
        {text: "D.M.YYYY HH:mm:ss", value: "2.1.2006 15:04:05"},
        {text: "D-MMM-YY HH:mm:ss", value: "2-Jan-06 15:04:05"},
        {text: "DD/MM/YYYY H:mm:ss PM", value: "02/01/2006 3:04:05 PM"},
        {text: "MM/DD/YYYY H:mm:ss PM", value: "01/02/2006 3:04:05 PM"},
        {text: "YYYY-MM-DD H:mm:ss PM", value: "2006-01-02 3:04:05 PM"},
        {text: "Month D, YYYY H:mm:ss PM", value: "January 2, 2006 3:04:05 PM"},
        {text: "D MMM, YYYY H:mm:ss PM", value: "2 Jan, 2006 3:04:05 PM"},
        {text: "D MMMM, YYYY H:mm:ss PM", value: "2 January, 2006 3:04:05 PM"},
        {text: "DD/MM/YY H:mm:ss PM", value: "02/01/06 3:04:05 PM"},
        {text: "MM/DD/YY H:mm:ss PM", value: "01/02/06 3:04:05 PM"},
        {text: "YY-MM-DD H:mm:ss PM", value: "06-01-02 3:04:05 PM"},
        {text: "D/M/YYYY H:mm:ss PM", value: "2/1/2006 3:04:05 PM"},
        {text: "D.M.YYYY H:mm:ss PM", value: "2.1.2006 3:04:05 PM"},
        {text: "D-MMM-YY H:mm:ss PM", value: "2-Jan-06 3:04:05 PM"},
        {text: "DD/MM/YYYY HH:mm:ss.SSS", value: "02/01/2006 15:04:05.000"},
        {text: "MM/DD/YYYY HH:mm:ss.SSS", value: "01/02/2006 15:04:05.000"},
        {text: "YYYY-MM-DD HH:mm:ss.SSS", value: "2006-01-02 15:04:05.000"},
        {text: "Month D, YYYY HH:mm:ss.SSS", value: "January 2, 2006 15:04:05.000"},
        {text: "D MMM, YYYY HH:mm:ss.SSS", value: "2 Jan, 2006 15:04:05.000"},
        {text: "D MMMM, YYYY HH:mm:ss.SSS", value: "2 January, 2006 15:04:05.000"},
        {text: "DD/MM/YY HH:mm:ss.SSS", value: "02/01/06 15:04:05.000"},
        {text: "MM/DD/YY HH:mm:ss.SSS", value: "01/02/06 15:04:05.000"},
        {text: "YY-MM-DD HH:mm:ss.SSS", value: "06-01-02 15:04:05.000"},
        {text: "D/M/YYYY HH:mm:ss.SSS", value: "2/1/2006 15:04:05.000"},
        {text: "D.M.YYYY HH:mm:ss.SSS", value: "2.1.2006 15:04:05.000"},
        {text: "D-MMM-YY HH:mm:ss.SSS", value: "2-Jan-06 15:04:05.000"},
        {text: "DD/MM/YYYY H:mm:ss.SSS PM", value: "02/01/2006 3:04:05.000 PM"},
        {text: "MM/DD/YYYY H:mm:ss.SSS PM", value: "01/02/2006 3:04:05.000 PM"},
        {text: "YYYY-MM-DD H:mm:ss.SSS PM", value: "2006-01-02 3:04:05.000 PM"},
        {text: "Month D, YYYY H:mm:ss.SSS PM", value: "January 2, 2006 3:04:05.000 PM"},
        {text: "D MMM, YYYY H:mm:ss.SSS PM", value: "2 Jan, 2006 3:04:05.000 PM"},
        {text: "D MMMM, YYYY H:mm:ss.SSS PM", value: "2 January, 2006 3:04:05.000 PM"},
        {text: "DD/MM/YY H:mm:ss.SSS PM", value: "02/01/06 3:04:05.000 PM"},
        {text: "MM/DD/YY H:mm:ss.SSS PM", value: "01/02/06 3:04:05.000 PM"},
        {text: "YY-MM-DD H:mm:ss.SSS PM", value: "06-01-02 3:04:05.000 PM"},
        {text: "D/M/YYYY H:mm:ss.SSS PM", value: "2/1/2006 3:04:05.000 PM"},
        {text: "D.M.YYYY H:mm:ss.SSS PM", value: "2.1.2006 3:04:05.000 PM"},
        {text: "D-MMM-YY H:mm:ss.SSS PM", value: "2-Jan-06 3:04:05.000 PM"},
        {text: "DD/MM/YYYY | HH:mm:ss", value: "02/01/2006 | 15:04:05"},
        {text: "MM/DD/YYYY | HH:mm:ss", value: "01/02/2006 | 15:04:05"},
        {text: "YYYY-MM-DD | HH:mm:ss", value: "2006-01-02 | 15:04:05"},
        {text: "Month D, YYYY | HH:mm:ss", value: "January 2, 2006 | 15:04:05"},
        {text: "D MMM, YYYY | HH:mm:ss", value: "2 Jan, 2006 | 15:04:05"},
        {text: "D MMMM, YYYY | HH:mm:ss", value: "2 January, 2006 | 15:04:05"},
        {text: "DD/MM/YY | HH:mm:ss", value: "02/01/06 | 15:04:05"},
        {text: "MM/DD/YY | HH:mm:ss", value: "01/02/06 | 15:04:05"},
        {text: "YY-MM-DD | HH:mm:ss", value: "06-01-02 | 15:04:05"},
        {text: "D/M/YYYY | HH:mm:ss", value: "2/1/2006 | 15:04:05"},
        {text: "D.M.YYYY | HH:mm:ss", value: "2.1.2006 | 15:04:05"},
        {text: "D-MMM-YY | HH:mm:ss", value: "2-Jan-06 | 15:04:05"},
        {text: "DD/MM/YYYY HH:mm:ss Z", value: "02/01/2006 15:04:05 Z"},
        {text: "MM/DD/YYYY HH:mm:ss Z", value: "01/02/2006 15:04:05 Z"},
        {text: "YYYY-MM-DD HH:mm:ss Z", value: "2006-01-02 15:04:05 Z"},
        {text: "Month D, YYYY HH:mm:ss Z", value: "January 2, 2006 15:04:05 Z"},
        {text: "D MMM, YYYY HH:mm:ss Z", value: "2 Jan, 2006 15:04:05 Z"},
        {text: "D MMMM, YYYY HH:mm:ss Z", value: "2 January, 2006 15:04:05 Z"},
        {text: "DD/MM/YY HH:mm:ss Z", value: "02/01/06 15:04:05 Z"},
        {text: "MM/DD/YY HH:mm:ss Z", value: "01/02/06 15:04:05 Z"},
        {text: "YY-MM-DD HH:mm:ss Z", value: "06-01-02 15:04:05 Z"},
        {text: "D/M/YYYY HH:mm:ss Z", value: "2/1/2006 15:04:05 Z"},
        {text: "D.M.YYYY HH:mm:ss Z", value: "2.1.2006 15:04:05 Z"},
        {text: "D-MMM-YY HH:mm:ss Z", value: "2-Jan-06 15:04:05 Z"},
        {text: "DD MMM 'YY | hh:mm a", value: "02 Mar '23 | 11:46 am"},
      ],
      dateOptionsForTable: {
        "02/01/2006": "DD/MM/YYYY",
        "01/02/2006": "MM/DD/YYYY",
        "2006-01-02": "YYYY-MM-DD",
        "January 2, 2006": "Month D, YYYY",
        "2 Jan, 2006": "D MMM, YYYY",
        "2 January, 2006": "D MMMM, YYYY",
        "02/01/06": "DD/MM/YY",
        "01/02/06": "MM/DD/YY",
        "06-01-02": "YY-MM-DD",
        "2/1/2006": "D/M/YYYY",
        "2.1.2006": "D.M.YYYY",
        "2-Jan-06": "D-MMM-YY",
        "02-01-2006 15:04:05": "DD-MM-YYYY HH:mm:ss",
        "01-02-2006 15:04:05": "MM-DD-YYYY HH:mm:ss",
        "2006-01-02 15:04:05": "YYYY-MM-DD HH:mm:ss",
        "January 2, 2006 15:04:05": "Month D, YYYY HH:mm:ss",
        "2 Jan, 2006 15:04:05": "D MMM, YYYY HH:mm:ss",
        "2 January, 2006 15:04:05": "D MMMM, YYYY HH:mm:ss",
        "02/01/06 15:04:05": "DD/MM/YY HH:mm:ss",
        "01/02/06 15:04:05": "MM/DD/YY HH:mm:ss",
        "06-01-02 15:04:05": "YY-MM-DD HH:mm:ss",
        "2/1/2006 15:04:05": "D/M/YYYY HH:mm:ss",
        "2.1.2006 15:04:05": "D.M.YYYY HH:mm:ss",
        "2-Jan-06 15:04:05": "D-MMM-YY HH:mm:ss",
        "02/01/2006 3:04:05 PM": "DD/MM/YYYY H:mm:ss PM",
        "01/02/2006 3:04:05 PM": "MM/DD/YYYY H:mm:ss PM",
        "2006-01-02 3:04:05 PM": "YYYY-MM-DD H:mm:ss PM",
        "January 2, 2006 3:04:05 PM": "Month D, YYYY H:mm:ss PM",
        "2 Jan, 2006 3:04:05 PM": "D MMM, YYYY H:mm:ss PM",
        "2 January, 2006 3:04:05 PM": "D MMMM, YYYY H:mm:ss PM",
        "02/01/06 3:04:05 PM": "DD/MM/YY H:mm:ss PM",
        "01/02/06 3:04:05 PM": "MM/DD/YY H:mm:ss PM",
        "06-01-02 3:04:05 PM": "YY-MM-DD H:mm:ss PM",
        "2/1/2006 3:04:05 PM": "D/M/YYYY H:mm:ss PM",
        "2.1.2006 3:04:05 PM": "D.M.YYYY H:mm:ss PM",
        "2-Jan-06 3:04:05 PM": "D-MMM-YY H:mm:ss PM",
        "02/01/2006 15:04:05.000": "DD/MM/YYYY HH:mm:ss.SSS",
        "01/02/2006 15:04:05.000": "MM/DD/YYYY HH:mm:ss.SSS",
        "2006-01-02 15:04:05.000": "YYYY-MM-DD HH:mm:ss.SSS",
        "January 2, 2006 15:04:05.000": "Month D, YYYY HH:mm:ss.SSS",
        "2 Jan, 2006 15:04:05.000": "D MMM, YYYY HH:mm:ss.SSS",
        "2 January, 2006 15:04:05.000": "D MMMM, YYYY HH:mm:ss.SSS",
        "02/01/06 15:04:05.000": "DD/MM/YY HH:mm:ss.SSS",
        "01/02/06 15:04:05.000": "MM/DD/YY HH:mm:ss.SSS",
        "06-01-02 15:04:05.000": "YY-MM-DD HH:mm:ss.SSS",
        "2/1/2006 15:04:05.000": "D/M/YYYY HH:mm:ss.SSS",
        "2.1.2006 15:04:05.000": "D.M.YYYY HH:mm:ss.SSS",
        "2-Jan-06 15:04:05.000": "D-MMM-YY HH:mm:ss.SSS",
        "02/01/2006 3:04:05.000 PM": "DD/MM/YYYY H:mm:ss.SSS PM",
        "01/02/2006 3:04:05.000 PM": "MM/DD/YYYY H:mm:ss.SSS PM",
        "2006-01-02 3:04:05.000 PM": "YYYY-MM-DD H:mm:ss.SSS PM",
        "January 2, 2006 3:04:05.000 PM": "Month D, YYYY H:mm:ss.SSS PM",
        "2 Jan, 2006 3:04:05.000 PM": "D MMM, YYYY H:mm:ss.SSS PM",
        "2 January, 2006 3:04:05.000 PM": "D MMMM, YYYY H:mm:ss.SSS PM",
        "02/01/06 3:04:05.000 PM": "DD/MM/YY H:mm:ss.SSS PM",
        "01/02/06 3:04:05.000 PM": "MM/DD/YY H:mm:ss.SSS PM",
        "06-01-02 3:04:05.000 PM": "YY-MM-DD H:mm:ss.SSS PM",
        "2/1/2006 3:04:05.000 PM": "D/M/YYYY H:mm:ss.SSS PM",
        "2.1.2006 3:04:05.000 PM": "D.M.YYYY H:mm:ss.SSS PM",
        "2-Jan-06 3:04:05.000 PM": "D-MMM-YY H:mm:ss.SSS PM",
        "02/01/2006 | 15:04:05": "DD/MM/YYYY | HH:mm:ss",
        "01/02/2006 | 15:04:05": "MM/DD/YYYY | HH:mm:ss",
        "2006-01-02 | 15:04:05": "YYYY-MM-DD | HH:mm:ss",
        "02/01/2006 15:04:05 Z": "DD/MM/YYYY HH:mm:ss Z",
        "01/02/2006 15:04:05 Z": "MM/DD/YYYY HH:mm:ss Z",
        "2006-01-02 15:04:05 Z": "YYYY-MM-DD HH:mm:ss Z",
        "January 2, 2006 | 15:04:05": "Month D, YYYY | HH:mm:ss",
        "2 Jan, 2006 | 15:04:05": "D MMM, YYYY | HH:mm:ss",
        "2 January, 2006 | 15:04:05": "D MMMM, YYYY | HH:mm:ss",
        "02/01/06 | 15:04:05": "DD/MM/YY | HH:mm:ss",
        "01/02/06 | 15:04:05": "MM/DD/YY | HH:mm:ss",
        "06-01-02 | 15:04:05": "YY-MM-DD | HH:mm:ss",
        "2/1/2006 | 15:04:05": "D/M/YYYY | HH:mm:ss",
        "2.1.2006 | 15:04:05": "D.M.YYYY | HH:mm:ss",
        "2-Jan-06 | 15:04:05": "D-MMM-YY | HH:mm:ss",
        "January 2, 2006 15:04:05 Z": "Month D, YYYY HH:mm:ss Z",
        "2 Jan, 2006 15:04:05 Z": "D MMM, YYYY HH:mm:ss Z",
        "2 January, 2006 15:04:05 Z": "D MMMM, YYYY HH:mm:ss Z",
        "02/01/06 15:04:05 Z": "DD/MM/YY HH:mm:ss Z",
        "01/02/06 15:04:05 Z": "MM/DD/YY HH:mm:ss Z",
        "06-01-02 15:04:05 Z": "YY-MM-DD HH:mm:ss Z",
        "2/1/2006 15:04:05 Z": "D/M/YYYY HH:mm:ss Z",
        "2.1.2006 15:04:05 Z": "D.M.YYYY HH:mm:ss Z",
        "2-Jan-06 15:04:05 Z": "D-MMM-YY HH:mm:ss Z",
        "02 Mar '23 | 11:46 am": "DD MMM 'YY | hh:mm a"
      },
      fixedColumns: ["Date", "Amount", "Status", "Referance Id"],
      refundFixedColumns: ["Amount", "Date", "Referance Id", "Type"],
      feeOptions: [],
      taxOptions: [],
      currencies: [],
      flagsByCurrency: {}
    }
  },
  computed: {
    items() {
      return [
        {
          text: "Reconciliation",
          active: true,
        },
        {
          text: "File Management",
          active: true,
        },
      ];
    },
    fileManagementDataFromStore() {
      return this.$store.state.fileManagement.content;
    },
    convertCountryToCurrency() {
      return this.$store.getters["auth/getConvertCountry"];
    },
    stepsCount() {
      return `${this.formStep} / ${this.form.step1.file_type !== 'recon' ? 3 : (this.refundIncluded ? 5 : 4)}`;
    }
  },
  created() {
    this.currencies = Common.currencyList();
    this.flagsByCurrency = Common.flagByCurrency();
    this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
      country: null,
      paymentMethod: null
    }).then((response) => {
      this.paymentChannelList = response.filter((channel) => channel.is_enabled).map(pc => {
        return { name: pc['name'], key: pc['key'], 'channel_type': pc['channel_type'] }
      });
      this.formPaymentChannelList = cloneDeep(this.paymentChannelList);
      this.paymentChannelList.push({ name: 'All', key: 'All', 'channel_type': 'All' });
      this.selectedPSP = this.paymentChannelList[0];
    })
    this.form.step1.currency = this.convertCountryToCurrency;
  },
  methods: {
    fetchFileManagement() {
      const channel = this.formPaymentChannelList.find(channel => channel.value === this.selectedPSP.key);
      if(channel && channel.value) {
        this.form.step1.payment_channel_key = channel.value;
      }
      this.$store.dispatch(`fileManagement/${FETCH_FILE_MANAGEMENT_DATA}`, this.selectedPSP.key)
      .catch(this.errorNotify);
    },
    changeModalTableData(data, title) {
      this.modalTableData = data;
      this.modalTitle = title;
    },
    validateState(name, key) {
      const { $dirty, $error } = this.$v.form[key][name];
      return $dirty ? !$error : null;
    },
    readUploadedFile() {
      if(this.formStep === 3 || (this.formStep === 4 && this.refundIncluded)) {
        this.formStep++;
        return;
      }
      this.isSubmited = true;
      if(this.formStep === 1) {
        this.$v.form.step1.$touch();
        if (this.$v.form.step1.$error) {
          return;
        }
        this.formStep = 2;
        return;
      }

      if(this.formStep === 2) {
        if(this.$v.form.step2.$error) {
          this.$v.form.step2.$reset();
        }
        this.$v.form.step2.$touch();
        if (this.$v.form.step2.$error) {
          return;
        }
        if(this.isEdit && this.form.step1.source_name && !this.form.step2.file) {
          this.formStep = 3;
          return;
        }
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: 'binary', blankrows: true });
        if(!wb.SheetNames[this.form.step2.sheet_number]) {
          this.$notify({
            type: 'error',
            text: 'Please enter valid sheet number.',
            closeOnClick: true,
          });
          return;
        }
        if(this.refundIncluded && !wb.SheetNames[this.form.step2.refund_sheet_number]) {
          this.$notify({
            type: 'error',
            text: 'Please enter valid refund sheet number.',
            closeOnClick: true,
          });
          return;
        }
        const wsname = wb.SheetNames[this.form.step2.sheet_number];
        const ws = wb.Sheets[wsname];
       
        const keys = Object.keys(ws);
        const firstRow = keys[0] !== '!ref' ? keys[0].replace(/[^0-9]/g,"") : keys[1].replace(/[^0-9]/g,"");
        if((this.form.step2.offset + 1) < firstRow) {
          this.$notify({
            type: 'error',
            text: 'Please enter valid Offset.',
            closeOnClick: true,
          });
          return false;
        }
        const dataIndex = (this.form.step2.offset + 1) - firstRow;
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        if(data.length > 0) {

          if(!data[dataIndex] || (data[dataIndex].length < 4 && this.form.step1.file_type !== 'transaction') || (this.form.step1.file_type === 'transaction' && data[dataIndex].length < 6)) {
            let columnsCount = 'four';

            if(this.form.step1.file_type === 'transaction') {
              columnsCount = 'six';
            }

            this.$notify({
              type: 'error',
              text: `Please enter valid offset which have atleast ${columnsCount} header columns.`,
              closeOnClick: true,
            });
            return false;
          }

          const headers = data[dataIndex].filter((value) => (typeof value === 'string' ? value.trim() : value));

          this.fileHeaders = headers;
          this.feeOptions = headers;
          this.taxOptions = headers;
          this.form.all_headers = headers;
          if(this.form.charge_params.fee) {
            this.feeSelected(this.form.charge_params.fee);
          }
          if(this.form.charge_params.tax) {
            this.taxSelected(this.form.charge_params.tax);
          }
        } else {
          this.$notify({
            type: 'error',
            text: 'Unable to read data. Please upload valid excel file and sheet number.',
            closeOnClick: true,
          });
          return false;
        }

        if(this.refundIncluded && this.form.step1.file_type !== 'transaction') {
          const wsname2 = wb.SheetNames[this.form.step2.refund_sheet_number];
          const ws2 = wb.Sheets[wsname2];
          const refundData = XLSX.utils.sheet_to_json(ws2, { header: 1 });
          const refundKeys = Object.keys(ws2);
          const refundFirstRow = refundKeys[0] !== '!ref' ? refundKeys[0].replace(/[^0-9]/g,"") : refundKeys[1].replace(/[^0-9]/g,"");
          if((this.form.step2.refund_offset + 1) < refundFirstRow) {
            this.$notify({
              type: 'error',
              text: 'Please enter valid Refund Offset.',
              closeOnClick: true,
            });
            return false;
          }
          const refundDataIndex = (this.form.step2.refund_offset + 1) - refundFirstRow;

          if(refundData.length > 0) {

            if(!refundData[refundDataIndex] || refundData[refundDataIndex].length < 4) {
              this.$notify({
                type: 'error',
                text: 'Please enter valid refund offset which have atleast four header columns.',
                closeOnClick: true,
              });
              return false;
            }

            this.refundFileHeaders = refundData[refundDataIndex];
          } else {
            this.$notify({
              type: 'error',
              text: 'Unable to read data. Please upload valid excel file and refund sheet number.',
              closeOnClick: true,
            });
            return false;
          }
        }
        this.formStep = 3;
      }

      reader.readAsBinaryString(this.form.step2.file);
    },
    submitFormData() {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }

      let chargeParams = {};
      if(this.form.step1.file_type === 'recon') {

        if(this.form.charge_params.fee) {
          chargeParams.fee = this.form.charge_params.fee;
        }
  
        if(this.form.charge_params.tax) {
          chargeParams.tax = this.form.charge_params.tax;
        }
      }

      if(!this.refundIncluded || this.form.step1.file_type !== 'recon') {
        this.refundFileHeaders = [];
      }

      const payload = {
        ...this.form.step1,
        ...this.form.step2,
        charge_params: chargeParams,
        fileHeaders: this.fileHeaders,
        refundFileHeaders: this.refundFileHeaders,
        all_headers: this.form.all_headers
      };

      this.$store.dispatch(`fileManagement/${POST_FILE_MANAGEMENT_DATA}`, {formData: payload, refundIncluded: this.refundIncluded, currentData: this.currentItem}).then((response) => {
        this.fetchFileManagement();
        this.$notify({
          type: "success",
          text: response.message,
          closeOnClick: true,
        });
        this.$bvModal.hide('config-file-form-modal');
        this.resetModal();
      })
      .catch(this.errorNotify);
    },
    errorNotify(error) {
      const err = JSON.parse(error.message);
      this.$notify({
        type: "error",
        text: err.message,
        closeOnClick: true,
      });
    },
    resetModal() {
      this.form = {
        step2: {
          offset: 0,
          refund_offset: 0,
          sheet_number: 0,
          refund_sheet_number: 0,
          file: null,
        },
        step1: {
          display_name: "",
          source_date_format: "",
          currency: '',
          payment_channel_key: null
        },
        charge_params: {
          fee: "",
          tax: ""
        },
        all_headers: []
      }
      this.formStep = 1;
      this.isSubmited = false;
      this.isEdit = false;
      this.currentItem = null;
      this.refundIncluded = false;
      this.fileHeaders = [];
      this.refundFileHeaders = [];
      this.feeOptions = [];
      this.taxOptions = [];
      this.$v.$reset();
    },
    editFileConfig(item) {
      const dateFound = this.dateOptions.find((date) => date.value === item.source_date_format)
      this.currentItem = item;
      this.form = {
        step2: {
          offset: item.offset,
          sheet_number: item.sheet_number,
          refund_offset: item.refund_offset,
          refund_sheet_number: 0,
          file: null,
        },
        step1: {
          display_name: item.display_name,
          source_date_format: dateFound ? item.source_date_format : "",
          currency: item.currency,
          source_name: item.source_name,
          file_type: item.file_type
        },
        all_headers: item.all_headers || []
      };

      this.feeOptions = item.all_headers || [];
      this.taxOptions = item.all_headers || [];

      this.form.charge_params = {
        fee: item.charge_params.fee || "",
        tax: item.charge_params.tax || ""
      }

      const channel = this.formPaymentChannelList.find(channel => channel.value === item.payment_channel_key);

      this.form.step1.payment_channel_key = channel ? channel.value : "";

      this.fileTypeChanged(item.file_type);

      if(item.file_type === 'transaction') {
        this.fileHeaders = [
          item.header_mapping.date,
          item.header_mapping.amount,
          item.header_mapping.merchant_order_ref,
          item.header_mapping.channel_order_ref,
          item.header_mapping.channel_order_ref1,
          item.header_mapping.status,
        ];
      } else {
        this.fileHeaders = [
          item.header_mapping.date,
          item.header_mapping.amount,
          item.header_mapping.txn_type,
          item.header_mapping.txn_ref,
        ];
      }

      const allFileHeaders = item.all_headers.filter(header => !this.fileHeaders.includes(header));

      this.fileHeaders = [...this.fileHeaders, ...allFileHeaders];

      if(Object.values(item.refund_header_mapping).length === 4 && item.file_type !== 'transaction') {
        this.refundIncluded = true;

        this.refundFileHeaders = [
          item.refund_header_mapping.amount,
          item.refund_header_mapping.date,
          item.refund_header_mapping.txn_ref,
          item.refund_header_mapping.txn_type,
        ];

        const allRefundFileHeaders = item.all_headers.filter(header => !this.refundFileHeaders.includes(header));

        this.refundFileHeaders = [...this.refundFileHeaders, ...allRefundFileHeaders]

        this.form.step2.refund_sheet_number = item.refund_sheet_number > -1 ? item.refund_sheet_number : 0;

        this.form.step2.refund_offset = item.refund_offset;
      }
      this.isEdit = true;
    },
    taxSelected(value) {
      this.feeOptions = this.form.all_headers.filter(el => el !== value);
    },
    feeSelected(value) {
      this.taxOptions = this.form.all_headers.filter(el => el !== value);
    },
    refundOptionChanged(value) {
      if(value && this.isEdit && this.refundFileHeaders.length === 0) {
        this.refundFileHeaders = this.currentItem.all_headers;
      }
    },
    fileTypeChanged(type) {
      if(type === 'transaction') {
        this.fixedColumns = ['Date', 'Transaction Amount', 'MerchantOrderRef', 'ChannelOrderRef', 'ChannelOrderRef1', 'Status'];
      } else {
        this.fixedColumns = ["Date", "Amount", "Status", "Referance Id"];
      }
    }
  }
}
</script>

<template>
  <!-- <Layout>
    <PageHeader title="File Management" :items="items" /> -->
    <div>
      <div class="d-flex justify-content-between mb-4">
        <multiselect
          name="data_source"
          v-model="selectedPSP"
          :options="paymentChannelList"
          @input="fetchFileManagement"
          placeholder="Select Data Source"
          track-by="key"
          label="name"
          style="width: 300px;"
        ></multiselect>

        <b-btn
          variant="primary"
          v-b-modal.config-file-form-modal
          class="mt-auto ml-auto border-0 px-3"
          style="font-weight: 400; height: 44px; line-height: 20px; border-radius: 12px;"
        >
          Configure File
        </b-btn>
      </div>

      <b-modal id="config-file-form-modal" @hidden="resetModal" title="Configure File">
        <div v-show="formStep === 1">
          <div class="d-flex justify-content-between h5 mb-0">
            <p>File Details</p>
            {{ stepsCount }}
          </div>
  
          <b-form-group id="payment-channel-group" label="Payment Channel" label-for="payment-channel">
            <b-form-select
              id="payment-channel"
              name="payment-channel"
              v-model="form.step1.payment_channel_key"
              :options="formPaymentChannelList"
              :state="validateState('payment_channel_key', 'step1')"
              aria-describedby="payment-channel-live-feedback"
            ></b-form-select>
  
            <b-form-invalid-feedback id="payment-channel-live-feedback">This is a required field.</b-form-invalid-feedback>
          </b-form-group>
  
          <b-form-group id="date-input-group" label="Date Format" label-for="date-input">
            <b-form-select
              id="date-input"
              name="date-input"
              v-model="form.step1.source_date_format"
              :options="dateOptions"
              :state="validateState('source_date_format', 'step1')"
              aria-describedby="date-input-live-feedback"
            ></b-form-select>
  
            <b-form-invalid-feedback id="date-input-live-feedback">This is a required field.</b-form-invalid-feedback>
          </b-form-group>
  
          <b-form-group
            id="display-name"
            label="Display Name"
            label-for="display-name-input"
          >
            <b-form-input
              id="display-name-input"
              v-model="form.step1.display_name"
              :state="validateState('display_name', 'step1')"
              placeholder="Display Name"
              required
              aria-describedby="display-name-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="display-name-live-feedback">
              This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>
  
          <div class="form-group position-relative">
            <label class="text-primary mt-2">Currency</label>
            <img
                class="flag_images"
                :src="flagsByCurrency[form.step1.currency]"
                :alt="form.step1.currency"
            />
            <b-form-select
              name="currency"
              v-model="form.step1.currency"
              class="form-control custom-select-for-flag"
              :state="validateState('currency', 'step1')"
            >
              <b-form-select-option value="">{{ "Select Currency" }}</b-form-select-option>
              <b-form-select-option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</b-form-select-option>
            </b-form-select>
            <div v-if="$v.form.step1.currency.$error" class="text-danger font-size-12">{{ "This value is required" }}</div>
          </div>
  
          <b-form-checkbox
            v-if="!isEdit"
            id="transaction-file"
            v-model="form.step1.file_type"
            name="transaction-file"
            value="transaction"
            unchecked-value="recon"
            @change="fileTypeChanged"
          >
            Transaction File
          </b-form-checkbox>
        </div>
        <div v-if="formStep === 2">
          <div class="d-flex justify-content-between h5 mb-0">
            <p>Sheet Details</p>
            {{ stepsCount }}
          </div>
  
          <b-form-group
            id="offset"
            label="Offset"
            label-for="offset-input"
          >
            <b-form-input
              id="offset-input"
              v-model.number="form.step2.offset"
              :state="validateState('offset', 'step2')"
              type="number"
              placeholder="Offset"
              required
              aria-describedby="offset-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="offset-live-feedback">
              This is a required field and must be positive integer.
            </b-form-invalid-feedback>
          </b-form-group>
  
          <b-form-group
            id="sheet-no"
            label="Sheet Number"
            label-for="sheet-no-input"
          >
            <b-form-input
              id="sheet-no-input"
              v-model.number="form.step2.sheet_number"
              :state="validateState('sheet_number', 'step2')"
              type="number"
              placeholder="Sheet Number"
              required
              aria-describedby="sheet-no-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="sheet-no-live-feedback">
              This is a required field and must be positive integer.
            </b-form-invalid-feedback>
          </b-form-group>
  
          <b-form-group v-if="form.step1.file_type === 'recon'">
            <b-form-checkbox
              id="refund-included"
              v-model="refundIncluded"
              name="refund-included"
              :value="true"
              :unchecked-value="false"
              @input="refundOptionChanged"
            >
              Refund Included
            </b-form-checkbox>
          </b-form-group>
  
          <b-form-group
            v-if="refundIncluded && form.step1.file_type === 'recon'"
            id="refund_offset"
            label="Refund Offset"
            label-for="refund-offset-input"
          >
            <b-form-input
              id="refund-offset-input"
              v-model.number="form.step2.refund_offset"
              :state="validateState('refund_offset', 'step2')"
              type="number"
              placeholder="Refund Offset"
              required
              aria-describedby="refund-offset-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="refund-offset-live-feedback">
              This is a required field and must be positive integer.
            </b-form-invalid-feedback>
          </b-form-group>
  
          <b-form-group
            v-if="refundIncluded && form.step1.file_type === 'recon'"
            id="refund-sheet-no"
            label="Refund Sheet Number"
            label-for="refund-sheet-no-input"
          >
            <b-form-input
              id="refund-sheet-no-input"
              v-model.number="form.step2.refund_sheet_number"
              :state="validateState('refund_sheet_number', 'step2')"
              type="number"
              placeholder="Refund Sheet Number"
              required
              aria-describedby="refund-sheet-no-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="refund-sheet-no-live-feedback">
              This is a required field and must be positive integer.
            </b-form-invalid-feedback>
          </b-form-group>
  
          <b-form-group
            id="file"
            label="File"
            label-for="file-input"
          >
            <b-form-file
              v-model="form.step2.file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              :state="validateState('file', 'step2')"
              aria-describedby="file-live-feedback"
              accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ></b-form-file>
            <b-form-invalid-feedback id="file-live-feedback">
              This is a required field.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
  
        <div v-if="formStep === 3">
          <div class="d-flex justify-content-between h5 mb-0">
            <p>Transaction File Headers</p>
            {{ stepsCount }}
          </div>
          <div class="d-flex">
            <b-table-simple bordered style="height: fit-content;">
              <b-thead class="thead-light">
                <b-tr>
                  <b-th>
                    DEFAULT COLUMNS
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <div>
                  <b-tr v-for="(item, index) in fixedColumns" :key="index">
                    <b-td style="width: 100vw;">{{ item }}</b-td>
                  </b-tr>
                </div>
              </b-tbody>
            </b-table-simple>
  
            <b-table-simple bordered>
              <b-thead class="thead-light">
                <b-tr>
                  <b-th>
                    INPUT FILE COLUMNS
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <draggable :list="fileHeaders" class="list-group" handle=".handle">
                  <b-tr v-for="(element, index) in fileHeaders" :key="index">
                    <b-td style="width: 100vw;"><span class="handle" style="cursor: move;"><i class="mdi mdi-drag"></i></span> {{ element }}</b-td>
                  </b-tr>
                </draggable>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
  
        <div v-if="formStep === 4 && refundIncluded && form.step1.file_type === 'recon'">
          <div class="d-flex justify-content-between h5 mb-0">
            <p>Refund Transaction File Headers</p>
            {{ stepsCount }}
          </div>
          <div class="d-flex">
            <b-table-simple bordered style="height: fit-content;">
              <b-thead class="thead-light">
                <b-tr>
                  <b-th>
                    DEFAULT COLUMNS
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <div>
                  <b-tr v-for="(item, index) in refundFixedColumns" :key="index">
                    <b-td style="width: 100vw;">{{ item }}</b-td>
                  </b-tr>
                </div>
              </b-tbody>
            </b-table-simple>
  
            <b-table-simple bordered>
              <b-thead class="thead-light">
                <b-tr>
                  <b-th>
                    INPUT FILE COLUMNS
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <draggable :list="refundFileHeaders" class="list-group" handle=".handle">
                  <b-tr v-for="(element, index) in refundFileHeaders" :key="index">
                    <b-td style="width: 100vw;"><span class="handle" style="cursor: move;"><i class="mdi mdi-drag"></i></span> {{ element }}</b-td>
                  </b-tr>
                </draggable>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
  
        <div v-if="formStep === 5 || (formStep === 4 && !refundIncluded)">
          <div class="d-flex justify-content-between h5 mb-0">
            <p>Charge Params</p>
            {{ stepsCount }}
          </div>
  
          <b-form-group
            label="Fee Field"
          >
            <b-form-select @input="feeSelected" v-model="form.charge_params.fee" :options="feeOptions">
              <template #first>
                <b-form-select-option :value="null">-- Please select Fee Field --</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
  
          <b-form-group
            label="Tax Field"
          >
            <b-form-select @input="taxSelected" v-model="form.charge_params.tax" :options="taxOptions">
              <template #first>
                <b-form-select-option :value="null">-- Please select Tax Field --</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
  
        <template #modal-footer>
          <div class="mx-auto">
            <b-btn variant="light" class="mr-2" v-if="formStep === 2 || formStep === 3 || formStep === 4 || formStep === 5" @click="() => formStep = formStep - 1">Previous</b-btn>
            <b-btn variant="primary" v-if="formStep === 1 || formStep === 2 || (formStep === 3 && form.step1.file_type === 'recon') || (formStep === 4 && refundIncluded)" @click="readUploadedFile">Next</b-btn>
            <b-btn variant="primary" v-if="(formStep === 3 && form.step1.file_type !== 'recon') || (formStep === 4 && !refundIncluded) || formStep === 5" @click="submitFormData">Submit</b-btn>
          </div>
        </template>
      </b-modal>
  
      <div class="table-responsive text-center table-custom">
        <b-table
          class="table-centered"
          :items="fileManagementDataFromStore"
          :fields="fields"
          responsive="sm"
          :bordered="false"
          :small="true"
          sticky-header="500px"
          show-empty
          :empty-text="$t('common.no-records')"
          busy.sync="true"
          hover
        >
          <template v-slot:cell(source_date_format)="{value}">
            {{ dateOptionsForTable[value] || value }}
          </template>
          <template v-slot:cell(charge_params)="{item, value}">
            <img src="@/assets/images/threedot.png" :id="`charge_param_${item.index}`" @click="changeModalTableData(value, 'Charge Params')" style="width: 15px; cursor: pointer;" alt v-b-modal.table-data-modal>
            <TooltipTable :target="`charge_param_${item.index}`" :items="value" />
          </template>
          <template v-slot:cell(header_mapping)="{item, value}">
            <img src="@/assets/images/threedot.png" :id="`header_mapping_${item.index}`" @click="changeModalTableData(value, 'Header Mapping')" style="width: 15px; cursor: pointer;" alt v-b-modal.table-data-modal>
            <TooltipTable :target="`header_mapping_${item.index}`" :items="value" />
          </template>
          <template v-slot:cell(refund_header_mapping)="{item, value}">
            <img src="@/assets/images/threedot.png" :id="`refund_header_mapping_${item.index}`" @click="changeModalTableData(value, 'Refund Header Mapping')" style="width: 15px; cursor: pointer;" alt v-b-modal.table-data-modal>
            <TooltipTable :target="`refund_header_mapping_${item.index}`" :items="value" />
          </template>
          <template v-slot:cell(action)="{item}">
            <b-btn variant="light" size="sm" @click="editFileConfig(item)" v-b-modal.config-file-form-modal>Edit</b-btn>
          </template>
        </b-table>
      </div>
  
      <b-modal id="table-data-modal" :title="modalTitle" no-close-on-backdrop hide-footer centered>
        <b-table-simple header-border-variant="light" header-class="border-bottom-0">
          <b-tbody>
            <b-tr v-for="(item, key) in modalTableData" :key="key">
              <b-td>{{ key }}</b-td>
              <b-td>{{ item }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-modal>
    </div>
  <!-- </Layout> -->
</template>

<style scoped>
.cursor-pointer{
  cursor: pointer;
}
::v-deep .flag_images{
  position: absolute;
  width: 20px;
  top: 39px;
  left: 10px;
}
::v-deep .custom-select-for-flag{
  padding-left:35px ;
}
::v-deep .b-table-sticky-column:last-child {
  right: 0;
}
::v-deep .btn{
  border-radius: 4px;
}
.form-control{
  font-size: 14px;
}
::v-deep label{
  font-size: 14px;
}
.h5{
  font-weight: 400;
  font-family: inherit;
}
</style>